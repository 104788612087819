<template>
  <div>
    <page-layout class="fs-page">
      <div slot="headerContent">
        <h1 class="title">OCR文档管理</h1>
      </div>
      <div slot="extra">
        <a-button @click="handleAdd" type="primary">添加OCR文档</a-button>
      </div>
      <a-card :bordered="false">
        <div class="fs-search-box">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-form-item label="文档名称">
                <a-input v-model="searchParams.name" @change="search" placeholder="请输入文档名称"/>
              </a-form-item>
              <a-form-item class="f-r m-r-none">
                <a-button type="primary" @click="search" icon="search">查询</a-button>
                <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <a-table bordered
                 size="middle"
                 :rowKey="record => record.id"
                 @change="tableChange"
                 :pagination="pagination"
                 :columns="columns"
                 :dataSource="tableData"
                 :loading="tableLoading">
          <template slot="_index" slot-scope="text, record, index">{{index+1}}</template>
          <template slot="action" slot-scope="text, record">
            <a-button @click="handleEdit(record.document.id)" shape="circle" icon="edit" size="small"></a-button>
            <a-divider type="vertical"/>
            <a-button @click="handleView(record.document.id)" shape="circle" icon="menu" size="small"></a-button>
            <a-divider type="vertical"/>
            <a-button type="danger" @click="handleDelete(record.document.id)" shape="circle" icon="delete"
                      size="small"></a-button>
          </template>
          <template slot="type" slot-scope="text, record">
            <span v-if="record.document.type === 'WORD'">文档</span>
            <span v-if="record.document.type === 'EXCEL'">表格</span>
            <span v-if="record.document.type === 'OTHER'">其他</span>
          </template>
        </a-table>
      </a-card>
      <!--添加Modal-->
      <a-modal title="添加OCR文档基本信息"
               :visible="addModal"
               :maskClosable="false"
               @ok="handleAddOk"
               @cancel="closeAddModal">
        <Form ref="addRef" type="add"
              @addSuccess="addSuccess"
              @addError="addError">
        </Form>
      </a-modal>
      <!--修改Model-->
      <a-modal title="修改OCR文档基本信息"
               :visible="editModal"
               :maskClosable="false"
               @ok="handleEditOk"
               @cancel="closeEditModal">
        <Form ref="editRef" type="edit"
              @editSuccess="editSuccess"
              @editError="editError">
        </Form>
      </a-modal>
      <!--抽屉-->
      <a-drawer title="OCR文档信息"
                width="900px"
                placement="right"
                :closable="true"
                @close="detailClose"
                :visible="detailDrawer">
        <item-list ref="viewRef"></item-list>
      </a-drawer>
    </page-layout>
  </div>
</template>

<script>
  import entityCRUD from '../../common/mixins/entityCRUD'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { ocrDocumentColumns } from './common/common'
  import Form from './Form'
  import ItemList from './itemList'

  export default {
    name: 'ocrDocumentList',
    mixins: [entityCRUD],
    components: { Form, PageLayout, ItemList },
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.system.ocrDocument,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: '',
          userId: ''
        },
        initColumns: ocrDocumentColumns()
      }
    }
  }
</script>

<style scoped>

</style>