<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="名称"
                   :label-col="spotLabelCol"
                   :wrapper-col="spotWrapperCol">
        <a-input
          v-decorator="['name',
                {rules:[
                  {type:'string',required:true,message:'名称不能为空'}
                  ],trigger:'change'}
                ]"
          placeholder="请输入"></a-input>
      </a-form-item>
      <a-form-item label="文档类型"
                   :label-col="spotLabelCol"
                   :wrapper-col="spotWrapperCol">
        <a-radio-group :options="TypeData"
                       v-decorator="['TypeData',
                                     {rules:[{TypeData:'string',required:true,message:'请选择文档类型'}],
                                     trigger:'change',initialValue:'true'}]">
        </a-radio-group>
      </a-form-item>
      <a-form-item label="文档图片"
                   :label-col="spotLabelCol"
                   :wrapper-col="spotWrapperCol">
        <a-upload :action="actionUrl"
                  :headers="headers"
                  accept="image/*"
                  listType="picture-card"
                  name="image"
                  :fileList="fileList"
                  @preview="handlePreview"
                  @change="handleChange">
          <div v-if="fileList.length < 3">
            <a-icon type="plus"/>
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 98%" :src="previewImage"/>
        </a-modal>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { DEFAULT_X_AUTH_TOKEN } from '@/store/mutation-types'
  import SERVICE_URLS from '@/api/service.url'
  import VueCookie from 'vue-cookie'
  import { ocrDocumentVO } from './common/ocrDocumentVO'
  import { TypeData } from './common/common'

  export default {
    name: 'Form',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data() {
      return {
        ifOcr: false,
        headers: {},
        actionUrl: SERVICE_URLS.document.upload.path,
        previewVisible: false,
        previewImage: '',
        fileList: [
          // {
          //   uid: '-1',
          //   name: 'xxx.png',
          //   status: 'done',
          //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
          // }
        ],
        UserList: [],
        TypeData: TypeData(),
        spotLabelCol: { span: 4 },
        spotWrapperCol: { span: 19 },
        spinShow: false,
        formItem: ocrDocumentVO(),
        form: this.$form.createForm(this) //注册表单
      }
    },
    computed: {
      //获取当前单位
      currentOrg() {
        return this.$store.getters.currentOrg
      },
      currentUser() {
        return this.$store.getters.currentUser
      }
    },
    created() {
      this.header = { DEFAULT_X_AUTH_TOKEN: VueCookie.get(DEFAULT_X_AUTH_TOKEN) }
      //把当前单位id 赋值 给 this.formItem.organization.id
      this.formItem.ocrDocument.user.id = this.currentUser.id
    },
    methods: {
      //---------上传图片---------------
      handleCancel() {
        this.previewVisible = false
      },
      handlePreview(file) {
        this.previewImage = file.url || file.thumbUrl
        this.previewVisible = true
      },
      handleChange({ fileList }) {
        this.fileList = fileList
      },
      //--------上传图片-------------
      onChange(checked) {
        this.formItem.ocrDocument.ifOcr = checked
      },
      submitAdd() {
        this.spinShow = true
        this.form.validateFields((err, values) => { //效验并获得输入的值
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.system.ocrDocument.create,
              noTips: true,
              data: this.formItem,
              success: (data) => {
                this.formItem = ocrDocumentVO()
                this.$emit('addSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      setVOFields(values) {
        this.formItem.ocrDocument.name = values.name
        this.formItem.ocrDocument.type = values.TypeData
        this.formItem.imageIds = this.fileList
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          TypeData: this.formItem.type + '',
        })
      },
      loadData(id) {
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.system.ocrDocument.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            this.spinShow = false
          }
        })
      },
      submitEdit() {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.system.ocrDocument.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              noTips: true,
              success: (data) => {
                this.$emit('editSuccess', data.body)
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('editError')
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
</style>